import * as React from 'react';
import { connect } from 'react-redux';
import SessionExpiredDialog, { models as SessionModels } from 'lib/components/SessionExpiredDialog';
import Notification, { models as NotificationModels } from 'lib/components/Notification';
import { UnregisteredPaneContainer } from '../UnregisteredPane';
import { RegisteredPaneContainer } from '../RegisteredPane';
import * as style from './style.scss';
import { InitialStatesType } from '../../reducers';

interface AppProps {
  session: SessionModels.Session;
  notification: NotificationModels.Notification;
}

class App extends React.PureComponent<AppProps> {
  handlePaneResize() {
    // react-bootstrap-tableがwindowのresizeイベントをハンドリングしてサイズを変えているので
    // それをシミュレートするためにイベントを発行する
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { session, notification } = this.props;
    return (
      <div>
        <div className={`${style.container} d-flex align-items-stretch`}>
          <UnregisteredPaneContainer />
          <div className={style.separator} />
          <RegisteredPaneContainer />
        </div>
        <SessionExpiredDialog session={session} />
        <Notification notification={notification} />
      </div>
    );
  }
}

function mapStateToProps(state: InitialStatesType): AppProps {
  return {
    session: state.session,
    notification: state.notification,
  };
}

const AppContainer = connect(mapStateToProps)(App);

export {
  App,
  AppContainer,
};

import * as React from 'react';
import * as NotificationSystem from 'react-notification-system';
import * as duck from './duck';

interface NotifierProps {
  onNotify(message: string, options: any): void;
  notification?: duck.models.Notification;
}

class Notifier extends React.PureComponent<NotifierProps> {
  public static defaultProps = {
    notification: new duck.models.Notification(),
  };

  componentDidMount() {
    if (this.props.notification) {
      this.props.onNotify(this.props.notification.message, this.props.notification.getOptions());
    }
  }

  componentWillUpdate(nextProps: NotifierProps) {
    if (nextProps.notification) {
      this.props.onNotify(nextProps.notification.message, nextProps.notification.getOptions());
    }
  }

  render() {
    return (<div />);
  }
}

export { models } from './duck';
export default class Notification
  extends React.Component<{ notification: duck.models.Notification }> {
  public static defaultProps = {
    notification: new duck.models.Notification(),
  };

  addNotification = (message: string, options: any) => {
    if (!message) {
      return;
    }
    const {
      title, timeout, position, level,
    } = options;
    (this.refs.notificationSystem as NotificationSystem).addNotification({
      title,
      level,
      position,
      message,
      autoDismiss: timeout,
    });
  };

  render() {
    const style = {
      Containers: {
        DefaultStyle: {
          width: 400,
        },
      },
    };
    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Notifier
          onNotify={this.addNotification}
          notification={this.props.notification}
        />
      </div>
    );
  }
}

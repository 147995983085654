import {
  compose, createStore, applyMiddleware, Middleware, StoreCreator,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import loggerMiddleware from 'lib/middlewares/loggerMiddleware';

import { rootReducer, initialStates, InitialProps } from '../reducers';
import rootSaga from '../sagas';

export default function createRedshiftTableViewerStore(props: InitialProps) {
  const { commonTitleUuid } = props;
  const { app } = initialStates;

  // Redux expects to initialize the store using an Object, not an Immutable.Map
  const initialState = {
    app: app.merge({
      commonTitleUuid,
    }),
  };

  const sagaMiddleware = createSagaMiddleware();
  let middlewares = <Middleware[]>[sagaMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    middlewares = [...middlewares, loggerMiddleware as Middleware];
  }
  let composedStore: (s: StoreCreator) => any;
  if (process.env.NODE_ENV === 'production') {
    composedStore = compose(
      applyMiddleware(...middlewares),
    );
  } else {
    composedStore = compose(
      applyMiddleware(...middlewares),
      (<any>window).devToolsExtension ? (<any>window).devToolsExtension() : (f: any) => f,
    );
  }
  const storeCreator = composedStore(createStore);
  const store = storeCreator(rootReducer, initialState);

  sagaMiddleware.run(rootSaga);

  return store;
}
